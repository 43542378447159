import apolloClient from "../apollo";

export default async (query, variables, token) => {
  const client = apolloClient(token);
  const response = await client.query({
    query,
    variables,
    fetchPolicy: "no-cache",

  });
  return response.data;
};
