import React, { useEffect, useState } from "react";
import { Container, Menu, Accordion } from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from "lodash";

const SideSchemaSelector = (props) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const schemaGroups = [...new Set(_.map(props.schemas, "portalGroup"))];

    // Pushes 'Other' group to end of array
    schemaGroups.push(schemaGroups.splice(schemaGroups.indexOf("Other"), 1)[0]);

    setGroups(schemaGroups);
  }, [props.schemas, props.selectedSchema]);

  const renderGroupContent = (groupName) => {
    const groupSchemas = props.schemas.filter((i) => i.portalGroup === groupName);

    return groupSchemas.map((item, index) => {
      return (
        <Menu.Item
          key={`${groupName}-${index}`}
          name={item.name}
          active={props.activeItem === item.name}
          onClick={() => props.onSchemaSelected(item.name, item.schemaBuilderId, item.version, item._id)}
        >
          {item.name}
        </Menu.Item>
      );
    });
  };

  const isOnlyGroupOther = () => {
    if (groups.length === 1 && groups.includes("Other")) return true;
    return false;
  };

  const panels = groups.map((group, index) => ({
    key: `panel-${index}`,
    title: {
      content: group,
    },
    content: renderGroupContent(group),
  }));

  return (
    <Container className="workspace-place-order-container">
      <div className="workspace-place-order">
        <Menu className="workspace-sidebar" vertical pointing>
          <div className="place-order-header">Choose Your Look</div>
          {isOnlyGroupOther() ? (
            renderGroupContent("Other")
          ) : (
            <Accordion defaultActiveIndex={0} panels={panels} fluid styled />
          )}
        </Menu>
        {props.children}
      </div>
    </Container>
  );
};

SideSchemaSelector.propTypes = {
  schemas: PropTypes.array,
  activeItem: PropTypes.string,
  onSchemaSelected: PropTypes.func,
  selectedSchema: PropTypes.string,
  children: PropTypes.element,
};

export default SideSchemaSelector;
