import _, { get } from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Image, Menu, Sidebar, Responsive, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { withAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

import "./index.scss";
import img from "../../assets/wegolook.svg";
import { isUserConsumer, isUserAdmin } from "../../utilities/auth";

const config = require("../../config");

export class NavBar extends Component {
  state = {
    visible: false,
    activeItem: "",
  };

  handleItemClick = (e, { name }) => {
    const { history, location } = this.props;
    const { user } = this.props.auth0;
    const userInfo = get(user, "https://wegolook.com/user", {});
    const userId = userInfo._id;

    this.setState({ activeItem: name });

    const { logout, loginWithRedirect } = this.props.auth0;

    const goToProfile = () => {
      history.push(`/profile/${userId}`);
    };
    const goToAdmin = () => {
      history.push(`/client-admin`);
    };

    switch (name.toUpperCase().trim()) {
      case "LOG OUT":
        logout({ returnTo: config.REACT_APP_BASE_URL });
        localStorage.clear();
        break;
      case "SEARCH ORDERS":
        history.push("/search-orders");
        break;
      case "PLACE ORDER":
        history.push("/place-order");
        break;
      case "SUPPORT":
        break;
      case "EMAIL SUPPORT":
        break;
      case "(405) 795-5665":
        break;
      case "SIGN IN":
        loginWithRedirect({ appState: { returnTo: location.pathname, searchParams: location.search } });
        break;
      case "MY PROFILE":
        goToProfile(userId);
        break;
      case "ADMIN":
        goToAdmin();
        break;
      default:
        break;
    }
  };

  logoReroute = (e, { name }) => {
    const { user, isAuthenticated } = this.props.auth0;
    const isConsumer = isUserConsumer(user);
    this.setState({ activeItem: name });
    const { history } = this.props;
    if (isAuthenticated) {
      if (isConsumer) {
        history.push("/instant-inspections");
      } else {
        history.push("/place-order");
      }
    } else {
      history.push("/login");
    }
  };

  navBarMobile = (imgLogo) => {
    const { children, items } = this.props;
    const { visible } = this.state;
    return (
      <Sidebar.Pushable>
        <Sidebar
          className="topMenu"
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          content={_.map(items, (item) => {
            return item.content === "PROFILE" ? null : (
              <Menu.Item
                active={this.state.activeItem === item.name}
                onClick={this.handleItemClick}
                className="menuText"
                {...item}
              />
            );
          })}
          vertical
          visible={visible}
          direction="right"
        />
        <Sidebar.Pusher dimmed={visible} onClick={this.handlePusher} style={{ minHeight: "100vh" }}>
          <Menu className="topMenu" fixed="top" inverted>
            <Menu.Item>{imgLogo}</Menu.Item>
            <Menu.Item onClick={this.handleToggle} position="right">
              <Icon name="sidebar" />
            </Menu.Item>
          </Menu>
          {this.navBarChildren(children)}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  };

  navBarDesktop = (imgLogo) => {
    const { activeItem } = this.state;
    const { items } = this.props;
    const { user } = this.props.auth0;
    if (isUserAdmin(user)) {
      return (
        <Menu className="topMenu" fixed="top" inverted>
          <Menu.Item onClick={this.logoReroute}>{imgLogo}</Menu.Item>
          <Menu.Menu position="right">
            {_.map(items.slice(0, 2), (item) => (
              <Menu.Item
                onClick={this.handleItemClick}
                active={activeItem === item.name}
                className="menuText"
                {...item}
              />
            ))}
            {_.map(items, (item) => {
              if (item.content === "PROFILE") {
                return (
                  <Dropdown key={item.key} item text="PROFILE" className="menuText">
                    <Dropdown.Menu>
                      {_.map(
                        items.filter((itemOBJ) => itemOBJ.parent === "PROFILE"),
                        (i) => (
                          <Dropdown.Item
                            onClick={this.handleItemClick}
                            active={activeItem === item.name}
                            className="menuText"
                            {...i}
                          />
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              return null;
            })}
            {_.map(items, (item) => {
              if (item.content === "SUPPORT") {
                return (
                  <Dropdown key={item.key} item text="SUPPORT" className="menuText">
                    <Dropdown.Menu>
                      {_.map(
                        items.filter((itemOBJ) => itemOBJ.parent === "SUPPORT"),
                        (i) => (
                          <Dropdown.Item
                            onClick={this.handleItemClick}
                            active={activeItem === item.name}
                            className="menuText"
                            {...i}
                          />
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              return null;
            })}
          </Menu.Menu>
        </Menu>
      );
    } else if (!isUserAdmin(user) && isUserConsumer(user) && user != null) {
      return (
        <Menu className="topMenu" fixed="top" inverted>
          <Menu.Item onClick={this.logoReroute}>{imgLogo}</Menu.Item>
          <Menu.Menu position="right">
            {_.map(items.slice(0, 1), (item) => (
              <Menu.Item
                onClick={this.handleItemClick}
                active={activeItem === item.name}
                className="menuText"
                {...item}
              />
            ))}
            {_.map(items, (item) => {
              if (item.content === "LOG OUT") {
                return (
                  <Dropdown key={item.key} item text="PROFILE" className="menuText">
                    <Dropdown.Menu>
                      {_.map(
                        items.filter((itemOBJ) => itemOBJ.parent === "PROFILE" && itemOBJ.name !== "ADMIN"),
                        (i) => (
                          <Dropdown.Item
                            onClick={this.handleItemClick}
                            active={activeItem === item.name}
                            className="menuText"
                            {...i}
                          />
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              return null;
            })}
            {_.map(items, (item) => {
              if (item.content === "SUPPORT") {
                return (
                  <Dropdown key={item.key} item text="SUPPORT" className="menuText">
                    <Dropdown.Menu>
                      {_.map(
                        items.filter((itemOBJ) => itemOBJ.parent === "SUPPORT"),
                        (i) => (
                          <Dropdown.Item
                            onClick={this.handleItemClick}
                            active={activeItem === item.name}
                            className="menuText"
                            {...i}
                          />
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              return null;
            })}
          </Menu.Menu>
        </Menu>
      );
    } else if (!isUserAdmin(user) && user != null) {
      return (
        <Menu className="topMenu" fixed="top" inverted>
          <Menu.Item onClick={this.logoReroute}>{imgLogo}</Menu.Item>
          <Menu.Menu position="right">
            {_.map(items.slice(0, 2), (item) => (
              <Menu.Item
                onClick={this.handleItemClick}
                active={activeItem === item.name}
                className="menuText"
                {...item}
              />
            ))}
            {_.map(items, (item) => {
              if (item.content === "PROFILE") {
                return (
                  <Dropdown key={item.key} item text="PROFILE" className="menuText">
                    <Dropdown.Menu>
                      {_.map(
                        items.filter((itemOBJ) => itemOBJ.parent === "PROFILE" && itemOBJ.name !== "ADMIN"),
                        (i) => (
                          <Dropdown.Item
                            onClick={this.handleItemClick}
                            active={activeItem === item.name}
                            className="menuText"
                            {...i}
                          />
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              return null;
            })}
            {_.map(items, (item) => {
              if (item.content === "SUPPORT") {
                return (
                  <Dropdown key={item.key} item text="SUPPORT" className="menuText">
                    <Dropdown.Menu>
                      {_.map(
                        items.filter((itemOBJ) => itemOBJ.parent === "SUPPORT"),
                        (i) => (
                          <Dropdown.Item
                            onClick={this.handleItemClick}
                            active={activeItem === item.name}
                            className="menuText"
                            {...i}
                          />
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              return null;
            })}
          </Menu.Menu>
        </Menu>
      );
    }
    return (
      <Menu className="topMenu" fixed="top" inverted>
        <Menu.Item onClick={this.logoReroute}>{imgLogo}</Menu.Item>
        <Menu.Menu position="right">
          {_.map(items, (item) => (
            <Menu.Item
              onClick={this.handleItemClick}
              active={activeItem === item.name}
              className="menuText"
              {...item}
            />
          ))}
        </Menu.Menu>
      </Menu>
    );
  };

  navBarChildren = (children) => <div style={{ paddingTop: "5em" }}>{children}</div>;

  handlePusher = () => {
    const { visible } = this.state;
    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children } = this.props;
    const imgLogo = (
      <>
        <Image
          src={img}
          style={{
            width: 120,
            padding: 0,
            height: 50,
          }}
        />
      </>
    );

    return (
      <div className="workspace-navbar">
        <Responsive {...Responsive.onlyMobile}>{this.navBarMobile(imgLogo)}</Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          {this.navBarDesktop(imgLogo)}
          {this.navBarChildren(children)}
        </Responsive>
      </div>
    );
  }
}

NavBar.propTypes = {
  items: PropTypes.array,
};

export default connect()(withAuth0(withRouter(NavBar)));
