import React from "react";
import { Button, Container } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import "./index.css";

const config = require("../../../config");

const UnauthorizedPage = () => {
  const { logout } = useAuth0();

  const logoutAndClear = () => {
    logout({ returnTo: config.REACT_APP_BASE_URL });
    localStorage.clear();
  };

  return (
    <Container fluid textAlign="center" className="fill-screen">
      <h1>Unauthorized Access</h1>
      <p>
        You do not have access to this portion of the app. If you believe this is an error, please contact your system
        administrator.
      </p>
      <Button onClick={logoutAndClear}>Back to site</Button>
    </Container>
  );
};

export default UnauthorizedPage;
