import React, { useState, useEffect } from "react";
import ContentLoader, { BulletList } from "react-content-loader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { Loader, Form, Segment, Message, Modal, Header, Icon, Button, Radio } from "semantic-ui-react";
import axios from "axios";
import { withAuth0 } from "@auth0/auth0-react";
import moment from "moment-timezone";
import "./index.scss";
import getData from "../../graphql/query";
import getSchemaById from "../../graphql/queries/getSchemaById";
import SideSchemaSelector from "../side-schema-selector";
import SchemaViewer from "../schema-viewer";
import DeliveryOptions from "../delivery-options";
import MessagingModal from "../common/messaging";
import { updateClientSchemas } from "../../state/actions";
import PreSchedule from "./PreSchedule";

const PlaceOrder = ({ clientSchemas, auth0, dispatch, history, selection }) => {
  const [formValue, setFormValue] = useState({});
  const [schemaData, setSchemaData] = useState({});
  const [deliveryOptions, setDeliveryOptions] = useState(null);
  const [activeItem, setActiveItem] = useState("");
  const [schemaItems, setSchemaItems] = useState(null);
  const [selectedSchemaId, setSelectedSchemaId] = useState("");
  const [deliveryOptionsId, setId] = useState("");
  const [formData, setFormData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(false);
  const [selectedDeliveryOptionIndex, setSelectedDeliveryOptionIndex] = useState(2);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [fileData, setFileData] = useState({});
  const [locationSuggestions, setLocationSuggestions] = useState();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressCheck, setAddressCheck] = useState("entered");
  const [preschedule, setPreschedule] = useState(null);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [selectedAddress, setAddress] = useState("");
  const [isPrescheduleSelected, setIsPrescheduleSelected] = useState(false);
  const [prescheduleError, setPrescheduleError] = useState(false);
  const [dueDate, setDueDate] = useState(false);

  const separator = "////";

  useEffect(() => {
    const formValueData = JSON.parse(localStorage.getItem("formsValue"));
    if (clientSchemas.length === 0) {
      getSchemasForCompany();
    } else {
      setSchemaItems(clientSchemas);
      onSchemaSelected(
        clientSchemas[0].name,
        clientSchemas[0].schemaBuilderId,
        clientSchemas[0].version,
        clientSchemas[0]._id
      );
    }
    if (formValueData) {
      setFormValue(formValueData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValue) {
      localStorage.setItem("formsValue", JSON.stringify(formValue));
    }
  }, [formValue]);

  const callUpsApi = async (address, location) => {
    const token = await auth0.getAccessTokenSilently();
    const resp = await axios.post(`/ups`, address, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.data) {
      const upsResponse = resp.data;
      const upsData = { ...location };

      upsData[`${selectedSchemaId}${separator}order_information_delivery_recipient_name`] = upsResponse.name;
      upsData[`${selectedSchemaId}${separator}order_information_delivery_address_street`] = upsResponse.address;
      upsData[`${selectedSchemaId}${separator}order_information_delivery_address_city`] = upsResponse.city;
      upsData[`${selectedSchemaId}${separator}order_information_delivery_address_state`] = upsResponse.state;
      upsData[`${selectedSchemaId}${separator}order_information_delivery_address_zip`] = upsResponse.zipCode;
      upsData[`${selectedSchemaId}${separator}order_information_delivery_recipient_phone`] = upsResponse.phone;
      setFormValue((prevState) => {
        return { ...prevState, ...upsData };
      });
    }
  };
  const getSchemasForCompany = async () => {
    try {
      const token = await auth0.getAccessTokenSilently();
      const consumerSchemaVersions = await axios.get("/schemas", {
        params: { fields: "name,type,version,schemaBuilderId" },
        headers: { Authorization: `Bearer ${token}` },
      });
      const items = consumerSchemaVersions.data.filter((i) => i.version && i.schemaBuilderId);
      if (items.length > 0) {
        let newArray = [];
        if (schemaItems && schemaItems.length > 0) {
          newArray = [...items, ...schemaItems];
        } else {
          newArray = [...items];
        }

        // Adds 'portalGroup' to schemas that do not have that key and will set its value to 'Other'
        newArray.map((i, index) => {
          console.log(i.portalGroup, index);
          if (!i.portalGroup || i.portalGroup.trim() === "") {
            i.portalGroup = "Other";
          }

          return i;
        });

        setSchemaItems(newArray);
        dispatch(updateClientSchemas(newArray));
        onSchemaSelected(items[0].name, items[0].schemaBuilderId, items[0].version, items[0]._id);
      } else {
        setSchemaItems([]);
      }
    } catch (err) {
      setError(err);
    }
  };

  const onButtonChange = (currentValue, deliveryOptionIndex) => {
    setValue(currentValue);
    setSelectedDeliveryOptionIndex(deliveryOptionIndex);
  };

  const _getDeliveryOptions = async (id) => {
    const token = await auth0.getAccessTokenSilently();

    try {
      const deliveryOptionsData = await axios.get(`/deliveryOptions/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      deliveryOptionsData.data.deliveryOptions.sort((a, b) => {
        let firstDeliveryOption;
        let secondDeliveryOption;
        if (typeof a.price === "number") firstDeliveryOption = a.price;
        else firstDeliveryOption = a.price.US.amount;
        if (typeof b.price === "number") secondDeliveryOption = b.price;
        else secondDeliveryOption = b.price.US.amount;
        return firstDeliveryOption - secondDeliveryOption;
      });

      setLoading(false);
      setDeliveryOptions(deliveryOptionsData.data);
      const defautlDeliveryOption = deliveryOptionsData.data.deliveryOptions.find((i) => i.price === 0);
      setSelectedDeliveryOptionIndex(defautlDeliveryOption.value);
    }
     catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const onSchemaSelected = async (schemaName, schemaId, version, id) => {
    setActiveItem(schemaName);
    setSelectedSchemaId(schemaId);
    setId(id);
    try {
      setLoading(true);
      const token = await auth0.getAccessTokenSilently();
      // graphqlapi
      const data = await getData(
        getSchemaById,
        { schemaId: [{ collectionId: schemaId, publishedVersion: version }] },
        token
      );
      if (data.schemaById === null || data.schemaById.length === 0) {
        setError("Schema not Published yet");
        return null;
      }
      let { fields } = data.schemaById[0];
      const { groups } = data.schemaById[0];
      let hasDueDate = false;
      //check if the id property includes the string "due_date"
      fields.forEach((field) => {
        if (field.id.includes("due_date")) {
          hasDueDate = true;
        }
        // associate schemaId with id of each field which helps to identify each field uniquely
        field.id = `${schemaId}${separator}${field.id}`;
      });

      //grouping related fields together where the schemaId can help identify which fields belong to which group.
      groups.forEach((group) => {
        group.fields = group.fields.map((field) => `${schemaId}${separator}${field}`);
      });
      setSchemaData(data.schemaById[0]);
      setFormData(true);
      setDueDate(hasDueDate);
  
      // delivery options api
      const { deliveryOptions: graphqlDeliveryOption } = data.schemaById[0].info;
      if (graphqlDeliveryOption.length === 0) {
        setDeliveryOptions(null);
        return null;
      }
      if (!hasDueDate) {
        await _getDeliveryOptions(id);
      }
      setLoading(false);
    } catch (err) {
      setDeliveryOptions(null);
      setLoading(false);
    }
    return null;
  };  
  const onChange = (e, prop) => {
    const { form } = prop;

    let newObj = {};
    newObj = { ...newObj, ...form };
    setFormValue(newObj);
  };

  const onFileChange = (e, fieldId, label) => {
    const uploadLabel = label
      .split(" ")
      .join("_")
      .replace(/[^\w\s]/gi, "_")
      .toLowerCase();
    const fileObj = { [uploadLabel]: e.target.files[0] };
    setFileData((prevState) => {
      return { ...prevState, [fieldId]: fileObj };
    });
  };

  const onLocationChange = (address) => {
    const extracteAdd = Object.values(address)[0];
    setAddress(extracteAdd);
    setFormValue((prevState) => {
      return { ...prevState, ...address };
    });
  };

  const createNewLook = async (afterAddressConfirmation, updatedFormValue) => {
    if (!afterAddressConfirmation && locationSuggestions) {
      setShowAddressModal(true);
      return;
    }
    const { schemaGroup, subSchema } = schemaData.info;
    const schemaType = `${schemaGroup.replace(/ /g, "-").toLowerCase()}-${subSchema.replace(/ /g, "-").toLowerCase()}`;
    setLoadingBtn(true);

    try {
      const token = await auth0.getAccessTokenSilently();

      // Data to be passed to the API during the /looks POST request
      const dataForLook = {
        schema: { type: schemaType },
      };

      // The client form for the look
      const clientForm = {
        delivery_options: selectedDeliveryOptionIndex,
      };
      // If we have files to upload, add that data to the 'dataForLook' here
      if (Object.keys(fileData).length !== 0) {
        const fileDataCopy = { ...fileData };
        const fileUploadsData = {};
        for (const file in fileDataCopy) {
          if (fileDataCopy[file] && file.indexOf(selectedSchemaId) > -1) {
            fileUploadsData[file.split("////")[1]] = { file: Object.values(fileDataCopy[file])[0].name };
          }
        }
        dataForLook.uploads = fileUploadsData;
      }

      // Set the client form values
      const currentFormValue = updatedFormValue || { ...formValue };

      Object.keys(currentFormValue).forEach((key) => {
        if (currentFormValue[key]) {
          clientForm[key.split(separator)[1]] = currentFormValue[key]?? '';
        }
      });

      // Check if we require a preschedule date and
      const status = schemaData.info.preschedule && schemaData.info.preschedule.validations.status;
      if (status && status !== "disabled") {
        const timezone = moment.tz.guess();
        if (preschedule) {
          dataForLook.scheduledTime = {
            start: preschedule,
            timezone,
          };
          dataForLook.forms = {
            client: clientForm,
          };
        } else if (status === "required") {
          setFormErrorMessage("Preschedule date to be set");
          setTimeout(() => {
            setFormErrorMessage("");
          }, 6000);
          setLoadingBtn(false);
          return;
        }
      }

      // Send POST request to API to create look
      const newLook = await axios.post("/looks", dataForLook, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/vnd.wegolook.look.data.v1+json" },
      });

      const { createdAt, updatedAt, uploads } = newLook.data;
      const lookId = newLook.data._id;

      // Upload any files we have to S3
      Object.keys(currentFormValue).forEach(async (schemaKey) => {
        await uploadFilesToS3(schemaKey, uploads, clientForm);
        if (currentFormValue[schemaKey] && schemaKey.indexOf(selectedSchemaId) > -1) {
          clientForm[schemaKey.split(separator)[1]] = currentFormValue[schemaKey];
        }
      });

      clientForm.createdAt = createdAt;
      clientForm.updatedAt = updatedAt;
      clientForm._id = lookId;

      await saveLook(clientForm, lookId, currentFormValue, selectedSchemaId);
      setLoadingBtn(false);
    } catch (err) {
      setError(err.response ? `Error code ${err.response.status}: ${err.response.data.message} ` : err);
      setLoadingBtn(false);
    }
  };

  const uploadFilesToS3 = async (schemaKey, uploads, clientForm) => {
    if (fileData[schemaKey] && Object.keys(fileData[schemaKey]).length) {
      Object.keys(uploads).forEach(async (documentUploadId) => {
        if (documentUploadId === schemaKey.split(separator)[1]) {
          const newFormData = fileData[schemaKey][Object.keys(fileData[schemaKey])[0]];
          [clientForm[schemaKey.split(separator)[1]]] = uploads[documentUploadId].url.split("?");
          await axios.put(uploads[documentUploadId].url, newFormData);
        }
      });
    }
  };

  const saveLook = async (clientForm, lookId, formValueData, SchemaId) => {
    const token = await auth0.getAccessTokenSilently();
    await axios.put(`/looks/${lookId}/forms/client`, clientForm, {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/vnd.wegolook.look.data.v1+json" },
    });

    Object.keys(formValueData).forEach(async (schemaKey) => {
      if (formValueData[schemaKey] && schemaKey.indexOf(SchemaId) > -1) {
        formValueData[schemaKey] = null;
      }
    });
    localStorage.setItem("formsValue", JSON.stringify(formValueData));
    history.push(`/order-receipt/${lookId}`);
  };

  const renderSchemaClientWarning = () => (
    <Message warning>
      <p style={{ whiteSpace: "pre-line" }}>{schemaData.info.clientWarning}</p>
    </Message>
  );

  const renderAddressConfirmationModal = () => (
    <Modal
      open={showAddressModal}
      size="small"
      closeOnDimmerClick={false}
      closeIcon={true}
      onClose={() => setShowAddressModal(false)}
    >
      <Header icon>
        <Icon name="address card" />
        Please confirm address
      </Header>
      <Modal.Content>
        <Form.Field className="address-modal entered">
          <Radio
            name="address"
            value={"entered"}
            checked={addressCheck === "entered"}
            onChange={() => setAddressCheck("entered")}
          />
          <Icon name="map marker" size="large" />
          <b>Entered Address</b>
          <p>{formValue[Object.keys(locationSuggestions)[0]]}</p>
        </Form.Field>
        <Form.Field className="address-modal suggested">
          <Radio
            name="address"
            value={"suggested"}
            checked={addressCheck === "suggested"}
            onChange={() => setAddressCheck("suggested")}
          />
          <Icon name="map marker" size="large" />
          <b>Suggested Address</b>
          <p>{Object.values(locationSuggestions)[0]}</p>
        </Form.Field>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            setShowAddressModal(false);
            let updatedFormValue;
            if (addressCheck === "suggested") {
              updatedFormValue = { ...formValue, ...locationSuggestions };
              setFormValue(updatedFormValue);
            }
            createNewLook(true, updatedFormValue);
          }}
        >
          <Icon name="checkmark" /> Done
        </Button>
      </Modal.Actions>
    </Modal>
  );

  const _debounceApi = _.debounce(async (date) => {
    try {
      const token = await auth0.getAccessTokenSilently();

      const deliveryOptionsData = await axios.get(`/deliveryOptions/${deliveryOptionsId}`, {
        params: {
          start: date,
          address: `${selectedAddress}`,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = { ...deliveryOptionsData.data };

      setLoading(false);
      setDeliveryOptions(data);

      const found = _.find(data.deliveryOptions, (el) => el?.value === data?.deliveryOptionValue);

      setSelectedDeliveryOptionIndex(found.value);
      setIsPrescheduleSelected(true);
      setLoading(false);

      // eslint-disable-next-line no-shadow
    } catch (error) {
      setError(error);
    }
  }, 2000);

  const _onChangePrescheduleDate = async (date) => {
    if (!selectedAddress || selectedAddress === "" || selectedAddress === null) {
      setError("Please enter the look address prior to entering the schedule time.");
      setPrescheduleError(true);
      return;
    }

    if (!date) {
      _getDeliveryOptions(deliveryOptionsId);
      setIsPrescheduleSelected(false);
      setPreschedule(date);
      return;
    }
    setLoading(true);

    // const token = await auth0.getAccessTokenSilently();
    setPreschedule(date);
    _debounceApi(date);
  };

  const _renderErrorModal = () => {
    if (error && prescheduleError) {
      return <MessagingModal onClose={setError} headerText="Address Required" message={error.toString()} />;
    }
    if (error) {
      return <MessagingModal onClose={setError} headerText="Something went wrong!" message={error.toString()} />;
    }
    return null;
  };

  if (formData) {
    const hasDeliveryOptions = deliveryOptions?.deliveryOptions.length > 0
    return (
      <div className="workspace-placeorder">
        {_renderErrorModal()}
        <div className={`form-error-message ${formErrorMessage && "active"}`}>
          <p>{formErrorMessage}</p>
        </div>
        <SideSchemaSelector
          schemas={schemaItems}
          activeItem={activeItem}
          onSchemaSelected={onSchemaSelected}
          selectedSchema={selection}
        >
          <div className="schema-delivery-outer">
            {loading ? (
              <Loader active={true} content={<div style={{ fontWeight: "bold", color: "gray" }}>Loading</div>} />
            ) : (
              ""
            )}
            <Segment style={{ marginLeft: "1%" }} className="preview-view-editor">
              <div className="ui header">{activeItem}</div>
              {schemaData.info.clientWarning.length > 0 ? renderSchemaClientWarning() : ""}
            </Segment>
            <SchemaViewer
              formValue={formValue}
              schemaData={schemaData}
              onChange={onChange}
              onFileChange={onFileChange}
              onLocationChange={onLocationChange}
              onSubmit={() => createNewLook()}
              setLocationSuggestions={setLocationSuggestions}
              callUpsApi={callUpsApi}
            >
              {schemaData.info.preschedule &&
                schemaData.info.preschedule.validations.status &&
                schemaData.info.preschedule.validations.status !== "disabled" && (
                  <PreSchedule
                    selectedDate={preschedule}
                    setDate={_onChangePrescheduleDate}
                    required={schemaData.info.preschedule.validations.status === "required"}
                  />
                )}
              {deliveryOptions && hasDeliveryOptions && !dueDate && (
                <DeliveryOptions
                  schemaId={selectedSchemaId}
                  deliveryOptions={deliveryOptions}
                  buttonChanged={onButtonChange}
                  isPrescheduleSelected={isPrescheduleSelected}
                  selectedDeliveryOptionIndex={selectedDeliveryOptionIndex}
                />
              )}
              <Form.Button content="submit" disabled={loadingBtn} primary className="save-look-btn" />
            </SchemaViewer>
            {showAddressModal ? renderAddressConfirmationModal() : ""}
          </div>
        </SideSchemaSelector>
      </div>
    );
  }

  if (error) {
    return <MessagingModal onClose={setError} headerText="Something went wrong!" message={error.toString()} />;
  }

  if (schemaItems && schemaItems.length === 0) {
    return <h2> No Schemas for the logged in user.</h2>;
  }

  return (
    <div className="skeleton-outer">
      <BulletList className="skeleton-schemalist" />
      <ContentLoader className="skeleton-form" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { clientSchemas: state.schemaVersions.clientSchemas };
};

export default connect(mapStateToProps)(withAuth0(withRouter(PlaceOrder)));
