/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { Redirect, withRouter, useLocation, useParams } from "react-router-dom";
import { Segment, Button, Dimmer, Loader } from "semantic-ui-react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "lodash";

import getData from "../../graphql/query";
import getSchemaById from "../../graphql/queries/getSchemaById";
import SchemaViewer from "../schema-viewer";
import DeliverOptions from "../delivery-options";
import Payment from "../payment";
import MessagingModal from "../common/messaging";
import CONSUMER_ORDER_DATA from "../consumer-order-data";
import InspectionTypeHeader from "./inspection-type-header";
import InspectionTypeBody from "./inspection-type-body";
import "../instant-inspection/index.scss";

const config = require("../../config");

const {
  REACT_APP_BASE_URL,
  REACT_APP_PAYPAL_CHECKOUT_URL,
  REACT_APP_AUTHORIZENET_CHECKOUT_URL,
  REACT_APP_EBAY_AFFILIATE_ID,
} = config;

const InspectionType = () => {
  const [formValue, setFormValue] = useState({});
  const [deliveryOptionValue, setDeliveryOptionValue] = useState(false);
  const [deliveryOptionIndex, setDeliveryOptionIndex] = useState(2);
  const [deliveryOptionsData, setDeliveryOptionsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentValue, setPaymentValue] = useState("cc");
  const [orderingSchema, setOrderingSchema] = useState();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const location = useLocation();
  const { type } = useParams();
  const [consumerSchema, setConsumerSchema] = useState(null);
  const [consumerSchemaNotFound, setConsumerSchemaNotFound] = useState(false);
  const [ebayItem, setEbayItem] = useState(null);
  const [ebayItemNotFound, setEbayItemNotFound] = useState(false);
  const [affiliateID, setAffiliateID] = useState(false);

  useEffect(() => {
    const schema = CONSUMER_ORDER_DATA.find((i) => i.schemaName === type);
    if (schema) {
      const AffiliateID = new URLSearchParams(location.search).get("affiliateId");
      setAffiliateID(AffiliateID);
      if (affiliateID) schema.affiliateId = affiliateID;
      setConsumerSchema(schema);
    } else {
      setConsumerSchemaNotFound(true);
    }
  }, [type, location.search, affiliateID]);

  // Get ebay item if itemID query param exists
  useEffect(() => {
    const itemId = new URLSearchParams(location.search).get("itemID");
    const fetchEbayProduct = async () => {
      try {
        const data = await axios.get("/shopping", {
          params: {
            ItemID: `${itemId}`,
            IncludeSelector: `ItemSpecifics,Details`,
            callname: "GetSingleItem",
            responseencoding: "JSON",
            version: "515",
          },
        });

        const item = get(data, "data.Item");
        if (item) {
          setAffiliateID(REACT_APP_EBAY_AFFILIATE_ID);
          setEbayItem(item);
        } else {
          setEbayItemNotFound(true);
        }
      } catch (err) {
        setError(err);
      }
    };

    if (itemId) {
      fetchEbayProduct();
    }
  }, [type, location.search]);

  useEffect(() => {
    const paymentPriceValue = localStorage.getItem(`payment-value-${get(consumerSchema, "schemaType")}`);
    const consumerFormValue = JSON.parse(localStorage.getItem("consumerFormsValue"));
    if (paymentPriceValue) {
      setDeliveryOptionValue(paymentPriceValue);
    }
    if (consumerFormValue) {
      setFormValue(consumerFormValue);
    }
    if (ebayItem) {
      const formValueCopy = { ...formValue };
      formValueCopy.order_information_international_address = `${ebayItem.Location}, ${ebayItem.PostalCode}`;
      ebayItem.ItemSpecifics.NameValueList.map((i) => {
        switch (i.Name) {
          case "VIN":
            formValueCopy.vehicle_vin = i.Value[0];
            break;
          case "Year":
            if (type === "watercraft") formValueCopy.marine_year = i.Value[0];
            else formValueCopy.vehicle_year = i.Value[0];
            break;
          case "Make":
            if (type === "watercraft") formValueCopy.marine_make = i.Value[0];
            else formValueCopy.vehicle_make = i.Value[0];
            break;
          case "Exterior Color":
            formValueCopy.vehicle_color = i.Value[0];
            break;
          case "Model":
            formValueCopy.vehicle_model = i.Value[0];
            break;
          case "Engine Model":
            formValueCopy.marine_model = i.Value[0];
            break;
          default:
        }
        return null;
      });
      setFormValue((prevState) => {
        return { ...prevState, ...formValueCopy };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerSchema, ebayItem, location]);

  // Update formValue in localStorage
  useEffect(() => {
    if (formValue) {
      localStorage.setItem("consumerFormsValue", JSON.stringify(formValue));
    }
  }, [formValue]);

  // Load ordering schema
  useEffect(() => {
    const loadOrderingSchema = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const apiResponse = await axios.get(`/schemas`, {
          params: { fields: "name,type,version,schemaBuilderId", schemaType: consumerSchema.schemaType },
          headers: { Authorization: `Bearer ${token}` },
        });

        const apiSchema = apiResponse.data[0];
        const { schemaBuilderId, version, _id } = apiSchema;
        const deliveryOptionsResponse = axios.get(`/deliveryOptions/${_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await getData(
          getSchemaById,
          { schemaId: [{ collectionId: schemaBuilderId, publishedVersion: version }] },
          token
        );
        const [deliveryOptions, sbResponse] = await Promise.all([deliveryOptionsResponse, data]);
        setLoading(false);
        if (sbResponse.schemaById === null) {
          setError("Order doesn't exist, Please try again later.");
        } else {
          setOrderingSchema(sbResponse.schemaById[0]);
          setDeliveryOptionsData(deliveryOptions.data);
          const defautlDeliveryOption = deliveryOptions.data.deliveryOptions.find((i) => i.price === 0);
          setDeliveryOptionIndex(defautlDeliveryOption.value);
        }
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    };

    if (isAuthenticated && consumerSchema) {
      loadOrderingSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, consumerSchema]);

  const deliveryOptionChange = (value, selecteddeliveyOptionIndex) => {
    localStorage.setItem(`payment-value-${consumerSchema.schemaType}`, value);
    setDeliveryOptionValue(value);
    setDeliveryOptionIndex(selecteddeliveyOptionIndex);
  };

  const onFormChange = (e, prop) => {
    const { form } = prop;
    let newObj = {};
    newObj = { ...formValue, ...form };
    setFormValue(newObj);
  };

  const onLocationChange = (address) => {
    setFormValue((prevState) => {
      return { ...prevState, ...address };
    });
  };

  const postAuthorizeForm = (path, params) => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = path;

    for (const key in params) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  const orderingSchemaSubmit = async (transactionType) => {
    const { schemaGroup, subSchema } = orderingSchema.info;
    const schemaType = `${schemaGroup.replace(/ /g, "-").toLowerCase()}-${subSchema.replace(/ /g, "-").toLowerCase()}`;

    setLoading(true);

    const schemaData = removeNullKeys();
    try {
      const lookId = await createLook(schemaType, schemaData);

      const paymentTokenOptions = {
        paymentType: transactionType,
        transactionType: "authOnly",
        successUrl: `${REACT_APP_BASE_URL}/order-receipt/${lookId}`,
        cancelUrl: `${REACT_APP_BASE_URL}${location.pathname}${location.search}`,
      };

      const token = await getAccessTokenSilently();
      const paymentTokenResponse = await axios.post(`/looks/${lookId}/payment/tokens`, paymentTokenOptions, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const paymentToken = paymentTokenResponse.data.token;

      if (transactionType === "paypal") {
        window.open(`${REACT_APP_PAYPAL_CHECKOUT_URL}${paymentToken}`, "_self");
      } else {
        postAuthorizeForm(REACT_APP_AUTHORIZENET_CHECKOUT_URL, { token: paymentToken });
      }
      return null;
    } catch (err) {
      setLoading(false);
      setError(err.response ? `Error code ${err.response.status}: ${err.response.data.message} ` : err);
    }
    return null;
  };

  const removeNullKeys = () => {
    const schemaData = { ...formValue, ...{ delivery_options: deliveryOptionIndex.toString() } };
    Object.keys(schemaData).forEach((field) => {
      if (!schemaData[field] || schemaData[field].length === 0) {
        delete schemaData[field];
      }
    });
    return schemaData;
  };

  const createLook = async (schemaType, schemaData) => {
    const { affiliateId } = consumerSchema;
    const createLooksObject = { schema: { type: schemaType }, forms: { client: schemaData } };
    if (affiliateId) createLooksObject.affiliateId = affiliateId;
    const token = await getAccessTokenSilently();
    const newLook = await axios.post("/looks", createLooksObject, {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/vnd.wegolook.look.data.v1+json" },
    });

    return newLook.data._id;
  };

  const renderForm = () => {
    const videoAddonPrice = formValue && formValue.order_information_video_requested === "Yes + $35" ? 35 : 0;
    return (
      <div className="inspection-schema-viewer-wrapper">
        <SchemaViewer
          formValue={formValue}
          onChange={onFormChange}
          schemaData={orderingSchema}
          onSubmit={() => orderingSchemaSubmit(paymentValue)}
          onLocationChange={onLocationChange}
        >
          <DeliverOptions
            schemaId={orderingSchema._id}
            deliveryOptions={deliveryOptionsData}
            buttonChanged={deliveryOptionChange}
          />
          <Payment
            basePrice={Number(consumerSchema.price)}
            deliveryPrice={Number(deliveryOptionValue)}
            videoAddonPrice={videoAddonPrice}
            paymentValue={paymentValue}
            setPaymentValue={setPaymentValue}
          />
          <Button content="Continue to payment" primary />
        </SchemaViewer>
      </div>
    );
  };

  const renderError = () => {
    return <MessagingModal onClose={setError} headerText="Something went wrong!" message={error.toString()} />;
  };

  if (ebayItemNotFound) {
    return <Redirect to={{ pathname: "/404", state: { message: "Ebay Item Not Found" } }} />;
  }

  if (consumerSchemaNotFound) {
    return <Redirect to={{ pathname: "/404", state: { message: "Consumer Schema Not Found" } }} />;
  }

  if (!consumerSchema) {
    return (
      <Dimmer page active={true}>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div>
      {error ? renderError() : null}
      <Dimmer page active={loading}>
        <Loader />
      </Dimmer>

      <Segment.Group className="consumers-order-root">
        <InspectionTypeHeader consumerSchema={consumerSchema} />
        <InspectionTypeBody consumerSchema={consumerSchema} ebayItem={ebayItem} />
      </Segment.Group>

      {orderingSchema ? renderForm() : null}
    </div>
  );
};

export default withRouter(InspectionType);
