import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

import { Header, Segment, Button, Grid, Image, Dimmer, Loader } from "semantic-ui-react";
import MessagingModal from "./common/messaging";

import "./place-order/index.scss";
import logo from "../assets/wegolook.svg";

const RegisterLogin = ({ setModalOpen }) => {
  const { loginWithRedirect } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    return () => {
      setModalOpen(false);
    };
  }, [setModalOpen]);

  const renderError = () => {
    return (
      <MessagingModal
        onClose={setError}
        parentLoader={setLoading}
        headerText="Something went wrong!"
        message={error.toString()}
      />
    );
  };

  return (
    <div>
      {error ? renderError() : null}
      <Dimmer page active={loading}>
        <Loader />
      </Dimmer>
      <Grid className="login-grid" textAlign="center" stackable verticalAlign="middle">
        <Grid.Column>
          <Image className="modal-logo" src={logo} />
          <Segment stacked>
            {<Header as="h2" content={"Please Login or Sign Up First"} style={{ color: "#465665" }} />}
            <Button.Group>
              <Button
                onClick={() =>
                  loginWithRedirect({ appState: { returnTo: location.pathname, searchParams: location.search } })
                }
                primary
                size="large"
              >
                Login
              </Button>
              <Button.Or style={{ height: "auto" }} />
              <Button
                color="orange"
                className="signup-button"
                onClick={() =>
                  loginWithRedirect({
                    screen_hint: "signup",
                    appState: { returnTo: location.pathname, searchParams: location.search },
                  })
                }
              >
                Sign Up
              </Button>
            </Button.Group>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

RegisterLogin.propTypes = {
  setModalOpen: PropTypes.func,
};

export default withRouter(RegisterLogin);
