import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { get } from "lodash";

const NotFound = () => {
  const location = useLocation();
  const message = get(location, "state.message");

  return (
    <div>
      <Header size="huge">404 - Not Found!</Header>
      <Header size="large">{message}</Header>
      <Header size="medium">
        <Link to="/">Go Home</Link>
      </Header>
    </div>
  );
};

export default NotFound;
