import createReducer from "./create-reducer";

const initial = {
  consumerSchemas: [],
  clientSchemas: [],
};

const reducers = {
  UPDATE_CONSUMER_SCHEMAS: (state, value) => {
    return { ...state, consumerSchemas: value };
  },

  UPDATE_CLIENT_SCHEMAS: (state, value) => {
    return { ...state, clientSchemas: value };
  },

  LOG_OUT_USER: (state) => {
    return { ...state, consumerSchemas: [], clientSchemas: [] };
  },
};

export default createReducer(initial, reducers);
