import React from "react";
import PropTypes from "prop-types";
import { Item, List } from "semantic-ui-react";

const EbayDetails = ({ ebayItem }) => {
  const renderDetail = ({ Name, Value }) => (
    <List.Item>
      <strong>{Name}: </strong>
      {Value[0]}
    </List.Item>
  );

  const renderProducts = ({ PictureURL, Title, ItemSpecifics }) => (
    <Item.Group>
      <Item>
        <Item.Image size="small" src={PictureURL[0]} />
        <Item.Content>
          <Item.Header>{Title}</Item.Header>
          <Item.Description>
            <List>
              {ItemSpecifics.NameValueList.map((item) => {
                switch (item.Name) {
                  case "VIN":
                    return renderDetail(item);
                  case "Year":
                    return renderDetail(item);
                  case "Make":
                    return renderDetail(item);
                  case "Model":
                    return renderDetail(item);
                  case "Exterior Color":
                    return renderDetail(item);
                  case "Mileage":
                    return renderDetail(item);
                  default:
                    return null;
                }
              })}
            </List>
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  );
  return renderProducts(ebayItem);
};

EbayDetails.propTypes = {
  ebayItem: PropTypes.shape({
    PictureURL: PropTypes.string.isRequired,
    Title: PropTypes.number.isRequired,
    ItemSpecifics: PropTypes.shape({
      NameValueList: PropTypes.array.isRequired,
    }).isRequired,
  }),
};

export default EbayDetails;
