import { ApolloLink } from "apollo-link";

const createStandardVariablesLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.variables = { ...operation.variables };

    return forward(operation).map((response) => {
      return response;
    });
  });
};

export default createStandardVariablesLink;
