import { combineReducers } from "redux";

import dashboard from "./dashboard";
import companyId from "./login-form";
import schemaVersions from "./schemas-reducer";

export default combineReducers({
  schemaVersions,
  dashboard,
  companyId,
});
