import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Dimmer, Loader } from "semantic-ui-react";

const PrivateRoute = ({ component, ...args }) => <Route component={component} {...args} />;

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => (
    <Dimmer page active={true}>
      <Loader />
    </Dimmer>
  ),
});
