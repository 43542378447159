import React, { useState, useEffect } from "react";
import { Form, Radio, Segment } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import "./place-order/index.scss";

const DeliveryOptions = (props) => {
  const [checkedValue, setCheckedValue] = useState("");

  const { isPrescheduleSelected, selectedDeliveryOptionIndex } = props;

  useEffect(() => {
    const schemaValue = localStorage.getItem(`schema-${props.schemaId}`);
    if (schemaValue) {
      setCheckedValue(schemaValue);
    } else {
      setCheckedValue("");
    }
  }, [props.schemaId]);

  const handleChange = (e, { value }, price) => {
    const calculatedPrice = price === 0 ? 0 : (price.US.amount / 100).toFixed(2);
    setCheckedValue(value);
    localStorage.setItem(`schema-${props.schemaId}`, value);
    props.buttonChanged(calculatedPrice, value);
  };

  const getDeliveryDate = (deliveryOption) => {
    const { name, date, price } = deliveryOption;
    const endDate = moment(date).format("MMM Do YYYY");
    const deliveryPrice =
      price === 0 ? "" : parseFloat(price.US.amount) > 0.0 ? `${(price.US.amount / 100).toFixed(2)}` : "";
    return `${name} - ${deliveryPrice} (report delivered by ${endDate})`;
  };

  const _radioChecked = (deliveryOption) => {
    if (!isPrescheduleSelected) {
      if (checkedValue === "") {
        return deliveryOption.price === 0;
      }
      return Number(checkedValue) === deliveryOption.value;
    }

    return deliveryOption.value === selectedDeliveryOptionIndex;
  };

  const createDeliveryOptions = () => {
    const deliveryOptions = _.get(props, "deliveryOptions.deliveryOptions", []);
    // TODO: uncomment to sort delivery options, should this be value or days? - INT-267
    // const sortedDeliveryOptions = deliveryOptions.sort((a, b) => (a.value < b.value ? 1 : -1));
    return _.map(deliveryOptions, (deliveryOption) => {
      return (
        <Form.Field key={deliveryOption.value} style={{ textAlign: "left" }}>
          <Radio
            label={getDeliveryDate(deliveryOption)}
            name="radioGroup"
            value={deliveryOption.value}
            checked={_radioChecked(deliveryOption)}
            onChange={(e, value) => handleChange(e, value, deliveryOption.price)}
            disabled={isPrescheduleSelected}
          />
        </Form.Field>
      );
    });
  };

  return (
    <Form className="delivery-options-outer">
      <Segment>
        <Form.Field>
          <div className="ui header"> Delivery Options</div>
        </Form.Field>
        {createDeliveryOptions()}
      </Segment>
    </Form>
  );
};

DeliveryOptions.propTypes = {
  schemaId: PropTypes.string,
  deliveryOptions: PropTypes.object,
  buttonChanged: PropTypes.func,
};

export default DeliveryOptions;
