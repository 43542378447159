import { get } from "lodash";

export const isUserConsumer = (user) => {
  const userInfo = get(user, "https://wegolook.com/user", {});

  if (userInfo.company) {
    return false;
  }
  return true;
};

/**
 * Checks weather a user contains the 'client' or 'client admin' roles.
 *
 * @param {object} user - The user object returned from Auth0
 * @returns {boolean} True if user contains the 'client' or 'client admin' role
 */
export const isUserClient = (user) => {
  // Check if user is defined and if user contains either 'client' or 'client admin' role.
  if (
    user &&
    (user["https://wegolook.com/user"].roles.includes("client") ||
      user["https://wegolook.com/user"].roles.includes("client admin"))
  ) {
    return true;
  }
  return false;
};
export const isUserAdmin = (user) => {
  // Check if user who is a client has a clientAdmin role
  if (user && user["https://wegolook.com/user"].roles.includes("client admin")) {
    return true;
  }
  return false;
};
export const hasReadDrafts = (user) =>{
 // Check if user has scopes and can read draft data
 if (user && user["https://wegolook.com/user"].scopes && user["https://wegolook.com/user"].scopes.includes("read:reports_drafts")) {
  return true;
}
return false;
}; 
