import React, { useState } from "react";
import { Button, Container, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { withAuth0, useAuth0 } from "@auth0/auth0-react";
import LazyLoad from "react-lazyload";

import "./index.scss";
import MessagingModal from "../common/messaging";

const Login = () => {
  const [error, setError] = useState(null);
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();

  const consumerLink = () => {
    history.push("/instant-inspections");
  };

  const renderError = () => {
    return <MessagingModal onClose={setError} headerText="Something went wrong!" message={error.toString()} />;
  };

  const renderInfo = () => (
    <div className="login-screen-details">
      <Container>
        <Grid>
          <h1 className="main-header">Welcome to WeGoLook</h1>
          <Grid.Row>
            <Grid.Column>
              <div class="action-buttons">
                <Button className="login-button" fluid onClick={() => loginWithRedirect({})}>
                  Login
                </Button>
                {error ? renderError() : null}
              </div>
            </Grid.Column>
          </Grid.Row>
          <h1 className="sub-header">Not a business or organization?</h1>
          <Grid.Row>
            <Grid.Column>
              <Button className="login-button" fluid onClick={consumerLink}>
                Explore Our Products
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );

  return (
    <LazyLoad debounce={false} offset={500}>
      <div className="login-image">{renderInfo()}</div>
    </LazyLoad>
  );
};

export default connect()(withAuth0(withRouter(Login)));
