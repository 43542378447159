import autoImage from "./Images/auto-min_opt.jpg";
import commercialImage from "./Images/commercial_opt.jpg";
import motorcycleImage from "./Images/motorcycle_opt.jpg";
import RVImage from "./Images/RV_opt.jpg";
import waterCraftImage from "./Images/watercraft-min_opt.jpg";

export default [
  {
    label: "Auto",
    link: "/instant-inspections/auto",
    schemaName: "auto",
    id: ["6001"],
    img: autoImage,
    schemaType: "consumer-car-truck-premium",
    header: "Instant Inspection - Auto",
    subHeader:
      "Know what’s true about a vehicle you’re purchasing or evaluating online. Whatever you need, an Instant Auto Inspection from WeGoLook can make it happen.",
    proListData: [
      "Up to 64 photos (interior and exterior)",
      "VIN verification and damage verification",
      "Photos of Tire Tread Depth",
    ],
    conListData: [
      "Mechanical inspection, diagnostic or system checks",
      "Frame and body inspection",
      "Undercarriage inspection or photos",
      "(No guarantee that any / all rust will be identified). Our agents do not perform test drives on the vehicle.",
    ],
    price: "99",
    videoAddonPrice: "35",
    threeDayDeliveryPrice: "35",
    twoDayDeliveryPrice: "50",
    url: " https://demo.wegolook.com/looks/5fab09a11e9df00021af5485/reports/public.html",
    videoDetail: "Working Demonstration and Exterior Walkaround Videos",
  },
  {
    label: "Heavy Equipment",
    link: "/instant-inspections/heavy-equipment",
    schemaName: "heavy-equipment",
    img: commercialImage,
    id: ["63732", "66466"],
    schemaType: "consumer-heavy-equipment-premium",
    header: "Instant Inspection - Commercial/Heavy Equipment",
    subHeader:
      "When you're buying, selling, and managing commercial vehicles and/or heavy equipment, you need boots on the ground in multiple locations to help you keep an eye on everything.",
    proListData: [
      "Up to 41 photos (interior and exterior)",
      "VIN verification and damage verification",
      "Photos of Tire Tread Depth",
    ],
    conListData: [
      "Mechanical inspection, diagnostic or system checks",
      "Frame and body inspection",
      "Undercarriage inspection or photos",
      "(No guarantee that any / all rust will be identified). Our agents do not perform test drives on the vehicle.",
    ],
    price: "99",
    videoAddonPrice: "35",
    threeDayDeliveryPrice: "35",
    twoDayDeliveryPrice: "50",
    url: "https://demo.wegolook.com/looks/5734e5d4c0eec40300da6c41/reports/public.html",
    videoDetail: "Working Demonstration and Exterior Walkaround Videos",
  },
  {
    label: "Motorcycle",
    link: "/instant-inspections/motorcycle",
    schemaName: "motorcycle",
    id: ["6024", "90983", "6720"],
    img: motorcycleImage,
    schemaType: "consumer-motorcycle-premium",
    header: "Instant Inspection - Motorcycle",
    subHeader:
      "If you need to put eyes on a motorcycle that’s not in your area, you can learn everything you need to know, from potential damage to tire tread, using WeGoLook's Motorcycle Inspection.",
    proListData: ["Up to 48 photos", "VIN verification and damage verification", "Photos of Tire Tread Depth"],
    conListData: [
      "Mechanical inspection, diagnostic or system checks",
      "Frame and body inspection",
      "Undercarriage inspection or photos",
      "(No guarantee that any / all rust will be identified). Our agents do not perform test drives on the vehicle.",
    ],
    price: "99",
    videoAddonPrice: "35",
    threeDayDeliveryPrice: "35",
    twoDayDeliveryPrice: "50",
    url: "https://demo.wegolook.com/looks/597b535532df5a0004f82c4e/reports/public.html",
    videoDetail: "Working Demonstration and Exterior Walkaround Videos",
  },
  {
    label: "RV",
    link: "/instant-inspections/rv",
    schemaName: "rv",
    img: RVImage,
    id: ["66468", "50054"],
    schemaType: "consumer-rv-premium",
    header: "Instant Inspection - RV",
    subHeader: "Looking to invest in an RV and want to get a thorough look at it first? We can help with that.",
    proListData: [
      "Up to 58 photos(interior and exterior)",
      "VIN verification and damage verification",
      "Photos of Tire Tread Depth",
    ],
    conListData: [
      "Mechanical inspection, diagnostic or system checks",
      "Frame and body inspection",
      "Undercarriage inspection or photos",
      "(No guarantee that any / all rust will be identified). Our agents do not perform test drives on the vehicle.",
    ],
    price: "99",
    videoAddonPrice: "35",
    threeDayDeliveryPrice: "35",
    twoDayDeliveryPrice: "50",
    url: "https://demo.wegolook.com/looks/599765d286b57f00048afa30/reports/public.html",
    videoDetail: "Working Demonstration and Exterior Walkaround Videos",
  },
  {
    label: "Watercraft",
    link: "/instant-inspections/watercraft",
    schemaName: "watercraft",
    img: waterCraftImage,
    id: ["26429", "1295"],
    schemaType: "consumer-marine-premium",
    header: "Instant Inspection - Watercraft",
    subHeader:
      "Get a good look at a motorboat, yacht, sailboat, or any other watercraft, all without leaving your office or home. We’ll inspect so that you can be confident in your purchase or facilitate your sale.",
    proListData: ["Up to 22 photos(interior and exterior)", "VIN verification and damage verification"],
    conListData: [
      "Mechanical inspection, diagnostic or system checks",
      "Frame and body inspection",
      "Undercarriage inspection or photos",
      "(No guarantee that any / all rust will be identified). Our agents do not perform test drives on the vehicle.",
      "Photos from a ladder or other area deemed unsafe for our agents to capture safely.",
    ],
    price: "99",
    videoAddonPrice: "35",
    threeDayDeliveryPrice: "35",
    twoDayDeliveryPrice: "50",
    url: "https://demo.wegolook.com/looks/5995b93890776e0004ab34bb/reports/public.html",
    videoDetail: "Exterior/Interior Walkaround Videos",
  },
];
