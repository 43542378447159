import React from "react";
import PropTypes from "prop-types";
import { FormRenderer } from "@wegolook/schema-form-renderer";

const SchemViewer = (props) => {
  return (
    <FormRenderer
      {...props}
      schema={props.schemaData}
      formValue={props.formValue}
      onChange={props.onChange}
      renderContext="client-ordering"
      onSubmit={props.onSubmit}
      onFileChange={props.onFileChange}
      onLocationChange={props.onLocationChange}
      callUpsApi={props.callUpsApi}
      setLocationSuggestions={props.setLocationSuggestions}
    >
      {props.children}
    </FormRenderer>
  );
};

SchemViewer.propType = {
  formValue: PropTypes.object,
  schemaData: PropTypes.object,
  onChange: PropTypes.func,
  onFileChange: PropTypes.func,
  onLocationChange: PropTypes.func,
  setLocationSuggestions: PropTypes.func,
  callUpsApi: PropTypes.func,
};

export default SchemViewer;
