import React, { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

const MessagingModal = (props) => {
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <Modal open={modalOpen} onClose={() => (props.onClose ? props.onClose(false) : null)} size="fullscreen">
      <Modal.Header style={{ color: "white", backgroundColor: "#2185d0" }} content={props.headerText} />
      <Modal.Content>
        <p style={{ color: "red" }}>{props.message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            if (props.onClose) {
              props.onClose(false);
            }
            setModalOpen(false);
          }}
          style={{ color: "white", backgroundColor: "#D06C21" }}
          inverted
        >
          <Icon name="checkmark" /> OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

MessagingModal.propTypes = {
  onClose: PropTypes.func,
  headerText: PropTypes.string,
  message: PropTypes.string,
};

export default MessagingModal;
