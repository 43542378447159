import React from "react";
import { Form, Radio, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import "./instant-inspection/index.scss";
import paypal from "../assets/paypal.png";
import creditCards from "../assets/creditcards.png";

const Payment = (props) => {
  const { paymentValue, setPaymentValue } = props;

  const handleChange = () => {
    const newPaymentValue = paymentValue === "paypal" ? "cc" : "paypal";
    setPaymentValue(newPaymentValue);
  };

  const totalPrice = props.basePrice + props.deliveryPrice + props.videoAddonPrice;
  const estimatedTax = totalPrice * 0.2;

  return (
    <Segment.Group horizontal>
      <Segment padded>
        <Form.Field>
          <div className="ui header">Pay With</div>
        </Form.Field>
        <Form.Field>
          <Radio value="cc" checked={paymentValue === "cc"} name="payment" onChange={handleChange} />
          <img alt="cc" className="payment" src={creditCards} onClick={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio value="paypal" checked={paymentValue === "paypal"} name="payment" onChange={handleChange} />
          <img alt="paypal" className="payment" src={paypal} onClick={handleChange} />
        </Form.Field>
      </Segment>
      <Segment padded>
        <p>
          <b>Base Price</b>
          <span className="amount-number">
            <b>${props.basePrice}</b>
          </span>
        </p>
        <p>
          Delivery
          <span className="amount-number">
            <b>${props.deliveryPrice}</b>
          </span>
        </p>
        <p>
          Video Addon
          <span className="amount-number">
            <b>${props.videoAddonPrice}</b>
          </span>
        </p>
        <p>
          Estimated Tax (20%)<sup>*</sup>
          <span className="amount-number">
            <b>${estimatedTax.toFixed(2)}</b>
          </span>
        </p>
        <p>
          <b>Total Price</b>
          <span className="amount-number">
            <h3>${(totalPrice + estimatedTax).toFixed(2)}</h3>
          </span>
        </p>
        <div className="tax-info">
          <p style={{ lineHeight: "0px" }}>
            * Actual tax is not calculated until report delivery. Payment is not collected until report delivery.
          </p>
          <p style={{ lineHeight: "0px" }}>
            A hold will be placed with your payment vendor when your order is submitted.
          </p>
        </div>
      </Segment>
    </Segment.Group>
  );
};

Payment.propTypes = {
  basePrice: PropTypes.string,
  deliveryPrice: PropTypes.string,
  videoAddonPrice: PropTypes.string,
  paymentValue: PropTypes.string,
  setPaymentValue: PropTypes.func,
};

export default Payment;
