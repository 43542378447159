import createReducer from "./create-reducer";

const initial = {
  companyId: null,
};

const reducers = {
  SAVE_COMPANYID: (state, value) => {
    return { ...state, ...{ companyId: value } };
  },
  UPDATE_LOGIN_EMAIL: (state, value) => {
    return { ...state, ...{ email: value } };
  },
  UPDATE_LOGIN_PASSWORD: (state, value) => {
    return { ...state, ...{ password: value } };
  },
  UPDATE_LOGIN_TOC: (state, value) => {
    return { ...state, ...{ termsAndConditions: value } };
  },
  UPDATE_LOGIN_FIRST_NAME: (state, value) => {
    return { ...state, ...{ firstName: value } };
  },
  UPDATE_LOGIN_LAST_NAME: (state, value) => {
    return { ...state, ...{ lastName: value } };
  },
};

export default createReducer(initial, reducers);
