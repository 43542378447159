import React, { useState } from "react";
import { Segment, Header, Button, Grid, List, Divider, Modal, Icon } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import EbayOrder from "../ebay-details";
import RegisterLogin from "../register-login";

const InspectionTypeBody = ({ consumerSchema, ebayItem }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isAuthenticated } = useAuth0();

  return (
    <Segment>
      <Grid columns={2} stackable>
        <Grid.Column width={8}>
          <Header as="h3" style={{ fontSize: "2em", color: "#005b99" }}>
            Includes
          </Header>
          <List>
            {consumerSchema.proListData.map((item, index) => {
              return (
                <List.Item key={index}>
                  <List.Icon style={{ color: "green" }} name="smile outline" />
                  <List.Content>{item}</List.Content>
                </List.Item>
              );
            })}
          </List>
          <Header as="h3" style={{ fontSize: "2em", color: "#005b99" }}>
            Does not include
          </Header>
          <List>
            {consumerSchema.conListData.map((item, index) => {
              return (
                <List.Item key={index}>
                  <List.Icon style={{ color: "red" }} name="frown outline" />
                  <List.Content>{item}</List.Content>
                </List.Item>
              );
            })}
          </List>
        </Grid.Column>
        <Grid.Column width={7} floated="right">
          <Header as="h3" style={{ fontSize: "2em", color: "#005b99" }}>
            Base price: ${consumerSchema.price}
          </Header>
          {ebayItem ? <EbayOrder ebayItem={ebayItem} /> : null}
          <Divider />
          <h3>Available add-ons</h3>
          <List>
            <List.Item>
              {consumerSchema.videoDetail} - ${consumerSchema.videoAddonPrice}
            </List.Item>
            <List.Item>Three Day Rush Delivery - ${consumerSchema.threeDayDeliveryPrice}</List.Item>
            <List.Item>Two Day Rush Delivery - ${consumerSchema.twoDayDeliveryPrice}</List.Item>
          </List>
          {!isAuthenticated ? (
            <Button className="small-button" onClick={() => setModalOpen(true)} primary>
              Buy Now
            </Button>
          ) : (
            ""
          )}
        </Grid.Column>
      </Grid>
      <Modal
        size="tiny"
        pinned
        open={modalOpen}
        content={
          <>
            <Icon onClick={() => setModalOpen(false)} name="close" />
            <RegisterLogin setModalOpen={setModalOpen} />
          </>
        }
      />
    </Segment>
  );
};

InspectionTypeBody.propTypes = {
  consumerSchema: PropTypes.object.isRequired,
  ebayItem: PropTypes.object,
};

export default InspectionTypeBody;
