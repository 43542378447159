import { ApolloLink } from "apollo-link";

function omitDeepArrayWalk(arr, key) {
  return arr.map((val) => {
    // Break on files, because it fails uploading otherwise for some reason.
    if (val instanceof File) {
      return val;
    }
    if (Array.isArray(val)) {
      return omitDeepArrayWalk(val, key);
    }
    if (typeof val === "object") {
      return omitDeep(val, key);
    }
    return val;
  });
}

export function omitDeep(obj, key) {
  const newObj = {};
  if (!obj) {
    return newObj;
  }
  const keys = Object.keys(obj);
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];

      // Break on files, because it fails uploading otherwise for some reason.
      if (val instanceof File) {
        newObj[i] = val;
        return;
      }
      if (Array.isArray(val)) {
        newObj[i] = omitDeepArrayWalk(val, key);
      } else if (typeof val === "object" && val !== null) {
        newObj[i] = omitDeep(val, key);
      } else {
        newObj[i] = val;
      }
    }
  });
  return newObj;
}

export const createTypeNameCleanerLink = () => {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, "__typename");
    }
    return forward(operation).map((response) => {
      return response;
    });
  });
};
