// When adding an item as a dropdown option add the key 'parent' with its parent as the value
const DASHBOARDITEMS = [
  { as: "a", content: "PLACE ORDER", key: "place order", name: "PLACE ORDER" },
  { as: "a", content: "SEARCH ORDERS", key: "search orders", name: "SEARCH ORDERS" },
  { as: "a", content: "PROFILE", key: "profile", name: "PROFILE" },
  { as: "a", content: "MY PROFILE", key: "my_profile", name: "MY PROFILE", parent: "PROFILE" },
  { as: "a", content: "ADMIN", key: "client_admin", name: "ADMIN", parent: "PROFILE" },
  { as: "a", content: "LOG OUT", key: "log out", name: "LOG OUT", parent: "PROFILE" },
  {
    as: "a",
    content: "SUPPORT",
    key: "support",
    name: "SUPPORT",
    href: "https://support.wegolook.com",
    parent: "SUPPORT",
  },
  {
    as: "a",
    content: "CALL US",
    key: "number-support",
    name: "CALL US",
    href: "tel:+18559471788",
    parent: "SUPPORT",
  },
  {
    as: "a",
    content: "(855) 947-1788",
    key: "number-support",
    name: "PHONE NUMBER",
    href: "tel:+18559471788",
    parent: "SUPPORT",
  },
  {
    as: "a",
    content: "EMAIL SUPPORT",
    key: "email-support",
    color: "blue",
    name: "EMAIL US",
    href: "mailto:customerservice@wegolook.com",
    parent: "SUPPORT",
  },
];

const LOGINITEMS = [
  {
    as: "a",
    content: "SUPPORT",
    key: "supportwegolook",
    name: "SUPPORT",
    href: "https://support.wegolook.com",
    target: "_blank",
    parent: "SUPPORT",
  },
  {
    as: "a",
    content: "WEGOLOOK.COM",
    key: "wegolook",
    name: "WEGOLOOK.COM",
    href: "https://wegolook.com",
    target: "_blank",
    parent: "SUPPORT",
  },
];

export { LOGINITEMS, DASHBOARDITEMS };
