import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { isUserConsumer, isUserClient } from "./utilities/auth";
import { LOGINITEMS, DASHBOARDITEMS } from "./components/menu/menu-items";
import NavBarMenu from "./components/menu";
import Routes from "./components/routes";
import MessagingModal from "./components/common/messaging";
import UnauthorizedPage from "./components/error-pages/unauthorized";
import "./App.scss";

const App = () => {
  const { isLoading, error, user, isAuthenticated } = useAuth0();
  if (isLoading) {
    return (
      <Dimmer page active={isLoading}>
        <Loader />
      </Dimmer>
    );
  }

  const isConsumer = isUserConsumer(user);
  const logout = { as: "a", content: "LOG OUT", key: "log out", name: "LOG OUT", parent: "PROFILE" };
  const login = { as: "a", content: "SIGN IN", key: "sign in", name: "SIGN IN" };
  const searchOrder = { as: "a", content: "SEARCH ORDERS", key: "search orders", name: "SEARCH ORDERS" };
  const searchOrderIndex = LOGINITEMS.find((item) => item.key === "search orders");
  if (isAuthenticated && isConsumer) {
    const isPresent = LOGINITEMS.find((item) => item.key === "log out");
    if (!isPresent) {
      LOGINITEMS.push(logout);
    }
    if (!searchOrderIndex) {
      LOGINITEMS.unshift(searchOrder);
    }
  }

  if (!isAuthenticated) {
    const index = LOGINITEMS.findIndex((item) => item.key === logout.key);
    const loginIndex = LOGINITEMS.findIndex((item) => item.key === login.key);
    if (index > -1) {
      LOGINITEMS.splice(index, 1);
    }
    if (searchOrderIndex > -1) {
      LOGINITEMS.splice(searchOrderIndex, 1);
    }
    if (loginIndex === -1) {
      LOGINITEMS.push(login);
    }
  }

  // Check if the user has permission to use this portion of the app
  if (isAuthenticated && !isUserClient(user)) {
    return <UnauthorizedPage></UnauthorizedPage>;
  }

  const renderError = () => {
    return <MessagingModal headerText="Something went wrong!" message={error.toString()} />;
  };

  return (
    <div className="workspace-app">
      <div className="menu-frame">
        <NavBarMenu items={isAuthenticated && !isConsumer ? DASHBOARDITEMS : LOGINITEMS}>
          <div className="body-frame">
            <Routes />
          </div>
        </NavBarMenu>
      </div>
      {error ? renderError() : null}
    </div>
  );
};

export default connect()(withRouter(App));
