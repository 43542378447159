const action = (type, value) => {
  const _action = { type };
  if (value) {
    _action.value = value;
  }
  return _action;
};

const actionCreator = (...theArgs) => {
  return (...args) => {
    return action(...theArgs, ...args);
  };
};

export const updateConsumerSchemas = actionCreator("UPDATE_CONSUMER_SCHEMAS");
export const updateClientSchemas = actionCreator("UPDATE_CLIENT_SCHEMAS");
export const logOutUser = actionCreator("LOG_OUT_USER");
