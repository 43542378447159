import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const config = require("./config");

const Auth0ProviderWithHistory = ({ children }) => {
  const {
    REACT_APP_AUTH0_API_AUDIENCE,
    REACT_APP_BASE_URL,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
  } = config;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    const path = appState?.returnTo || window.location.pathname;
    const searchParams = appState?.searchParams || "";
    history.push({
      pathname: path,
      search: searchParams,
    });
  };

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={REACT_APP_BASE_URL}
      onRedirectCallback={onRedirectCallback}
      audience={REACT_APP_AUTH0_API_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
