import _ from "lodash";
import React, { Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Loader, Dimmer } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "../private-route";
import { unprotectedRoutes, protectedRoutes } from "./route-defs";
import { isUserConsumer } from "../../utilities/auth";
import InstantInspection from "../instant-inspection/index";
import PlaceOrder from "../place-order/index";
import Login from "../login-form";
import InspectionType from "../inspection-type";
import NotFound from "../common/notfound";

const Routes = () => {
  const { user, isAuthenticated } = useAuth0();
  const isConsumer = isUserConsumer(user);

  const renderUnprotectedRoutes = (routes) => {
    return _.map(routes, (route) => {
      return <Route key={`route-${route.id}`} path={`${route.id}`} exact={route.exact} component={route.component} />;
    });
  };

  const renderProtectedRoutes = (routes) => {
    return _.map(routes, (route) => {
      return (
        <ProtectedRoute
          key={`route-${route.id}`}
          path={`${route.id}`}
          exact={route.exact}
          component={route.component}
        />
      );
    });
  };

  // TODO: find better way to do '/' route render
  return (
    <div className="workspace-frame">
      <Suspense
        fallback={
          <Dimmer active={true}>
            <Loader />
          </Dimmer>
        }
      >
        <Switch>
          <Route
            path={`/`}
            exact={true}
            render={() => {
              if (isAuthenticated) {
                if (isConsumer) {
                  const redirectComponent = JSON.parse(localStorage.getItem("consumerSchema")) ? (
                    <InspectionType />
                  ) : (
                    <InstantInspection />
                  );
                  return redirectComponent;
                } else {
                  return <PlaceOrder />;
                }
              } else {
                return <Login />;
              }
            }}
          />
          {renderUnprotectedRoutes(unprotectedRoutes)}
          {renderProtectedRoutes(protectedRoutes)}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default withRouter(Routes);
