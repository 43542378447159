import React, { useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Header, Segment, Card, Image, Dimmer, Loader } from "semantic-ui-react";
import heroImage from "assets/hero.png";

import "./index.scss";
import CONSUMER_ORDER_DATA from "../consumer-order-data";

const InstantInspectionPage = () => {
  const [loading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const openConsumerSchemaPage = (selectedSchema) => {
    history.push({
      pathname: selectedSchema.link,
      search: location.search,
    });
  };

  const createConsumerSchemasCards = () => {
    return CONSUMER_ORDER_DATA.map((data) => {
      return (
        <Card raised link onClick={() => openConsumerSchemaPage(data)}>
          <Card.Content>
            <Card.Header className="card-header">{`${data.label}`}</Card.Header>
          </Card.Content>
          <Image
            onClick={() => openConsumerSchemaPage(data)}
            className="card-image"
            fluid={true}
            src={data.img}
            alt="Auto Image"
          />
        </Card>
      );
    });
  };

  const renderLoader = () => {
    return (
      <Dimmer active page>
        <Loader indeterminate>Loading</Loader>
      </Dimmer>
    );
  };

  const renderHeroImageText = () => (
    <div>
      <center>Instant Inspections</center> <br />
      <h1 className="heroimage-sublabel">Our signature set of Looks available to order instantly online</h1>
    </div>
  );

  return (
    <div>
      {loading ? renderLoader() : null}
      <Segment vertical>
        <Image fluid={true} label={{ className: "heroimage-label", content: renderHeroImageText() }} src={heroImage} />
        <Header textAlign="center" as="h4" className="midsection-header">
          WeGoLook Products and Services
        </Header>
        <Card.Group className="card-group">{createConsumerSchemasCards()}</Card.Group>
      </Segment>
    </div>
  );
};

export default connect()(withRouter(InstantInspectionPage));
