import React from "react";

const InstantInspection = React.lazy(() => import("../instant-inspection"));
const InspectionType = React.lazy(() => import("../inspection-type"));
const EbayInspections = React.lazy(() => import("../ebay-inspections"));
const PlaceOrder = React.lazy(() => import("../place-order"));
const Login = React.lazy(() => import("../login-form"));
const SearchOrders = React.lazy(() => import("../search-orders"));
const OrderReceipt = React.lazy(() => import("../order-receipt"));
const DeliveryReport = React.lazy(() => import("../delivery-report"));
const Profile = React.lazy(() => import("../profile"));
const ClientAdmin = React.lazy(() => import("../client-admin"));
const ClientRegistration = React.lazy(() => import("../client-registration"))

export const protectedRoutes = [
  {
    id: "/place-order",
    component: PlaceOrder,
    label: "Place Order",
  },
  {
    id: "/search-orders",
    component: SearchOrders,
    label: "Search Orders",
  },
  {
    id: "/order-receipt/:id",
    component: OrderReceipt,
    label: "Order Receipt",
  },
  {
    id: "/delivery-report/:id",
    component: DeliveryReport,
    label: "Delivery Report",
  },
  {
    id: "/profile/:userId",
    component: Profile,
    label: "Profile",
  },
  {
    id: "/client-admin",
    component: ClientAdmin,
    label: "Admin",
  },
];

export const unprotectedRoutes = [
  {
    id: "/login",
    component: Login,
    label: "Login",
    exact: true,
  },
  {
    id: "/instant-inspections",
    component: InstantInspection,
    label: "Instant Inspection",
    exact: true,
  },
  {
    id: "/instant-inspections/:type",
    component: InspectionType,
    label: "Inspection",
  },
  {
    id: "/ebay-inspections",
    component: EbayInspections,
    label: "Ebay Inspection",
  },
  {
    id: "/client-registration",
    component: ClientRegistration,
    label: "Client Registration"
  }
];
