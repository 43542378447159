import React from "react";
import { useHistory } from "react-router-dom";
import { Segment, Header, Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const InspectionTypeHeader = ({ consumerSchema }) => {
  const history = useHistory();

  const openSampleReport = () => {
    const { url } = consumerSchema;
    window.open(url, "_blank");
  };

  return (
    <Segment textAlign="center">
      <Header
        as="h1"
        content={consumerSchema.header}
        inverted
        style={{
          fontSize: "4em",
          fontWeight: "normal",
          marginBottom: 0,
          marginTop: "1em",
        }}
      />
      <Header as="h2" content={consumerSchema.subHeader} inverted />
      <div style={{ marginTop: "3rem" }}>
        <Button className="consumer-buttons" onClick={() => history.push("/instant-inspections")} primary size="large">
          <Icon className="consumerIcons" name="angle left" />
          <b>Back to Instant Inspections</b>
        </Button>
        <Button className="consumer-buttons" onClick={openSampleReport} primary size="large">
          <Icon className="consumerIcons" name="file alternate">
            Sample Report
          </Icon>
        </Button>
      </div>
    </Segment>
  );
};

InspectionTypeHeader.propTypes = {
  consumerSchema: PropTypes.object.isRequired,
};

export default InspectionTypeHeader;
