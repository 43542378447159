import { InMemoryCache } from "apollo-cache-inmemory";
import fetch from "unfetch";
import { ApolloClient } from "apollo-client";
import { from } from "apollo-link";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

import { createTypeNameCleanerLink } from "./apollo-typename-cleaner";
import createStandardVariablesLink from "./standard-variables";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const standardVariablesLink = createStandardVariablesLink();
const typenameCleanerLink = createTypeNameCleanerLink();

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    if (object.__typename && object._id) {
      return `${object.__typename}-${object._id}`;
    }

    return object._id || null;
  },
});

const client = (token) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    link: from([
      errorLink,
      standardVariablesLink,
      typenameCleanerLink,
      authLink.concat(createHttpLink({ uri: "/graphql", fetch })),
    ]),
    cache,
  });
};

export default client;
