import gql from "graphql-tag";

export default gql`
  query getSchemaById($schemaId: [Schema_PublishedInput]) {
    schemaById: schemaByPublishedVersion(schemas: $schemaId) {
      _id
      createdAt
      updatedAt
      collectionId
      publishedVersion
      authorId
      status
      author {
        firstName
        lastName
      }
      companies {
        companyId
        name
        company {
          name
          deliveryInfo {
            cutoff {
              hour
              minute
              second
              timezone
            }
            weekendDelivery
            holidayDelivery
          }
        }
        lineItems {
          lineItemId
          required
          base
          conditions {
            any {
              all {
                field
                operator
                value
              }
            }
          }
          price {
            amount
            currency
          }
          lookerFee {
            amount
            currency
          }
          lineItem {
            name
            category
            itemCode
            glAccountNumber
            taxCode
          }
        }
        pipeline {
          skipQA
          skipPrep
          skipVerification
          autoSolicit
        }
      }
      lineItems {
        lineItemId
        required
        base
        conditions {
          any {
            all {
              field
              operator
              value
            }
          }
        }
        price {
          amount
          currency
        }
        lookerFee {
          amount
          currency
        }
        lineItem {
          name
          category
          itemCode
          glAccountNumber
          taxCode
        }
      }
      info {
        tags
        clientWarning
        preschedule {
          validations {
            id
            value
            status
          }
        }
        isConsumer
        requiredEquipment
        schemaName
        schemaDescription
        schemaGroup
        subSchema
        pipeline {
          skipQA
          skipPrep
          skipVerification
          autoSolicit
        }
        deliveryOptions {
          standard
          name
          days
          lookerDays
          price {
            amount
            currency
          }
          itemCode
        }
        estimatedTime {
          min
          max
        }
      }
      fields {
        id
        index
        label
        defaultValue
        component
        tooltipId
        tooltip {
          _id
          path
        }
        properties {
          label
          name
          value
        }
        options {
          id
          text
          value
        }
        validations {
          id
          value
          enabled
        }
        group
        reports {
          environment
          enabled
        }
        contexts {
          environment
          render
          conditions {
            as
            any {
              all {
                field
                operator
                value
              }
            }
          }
        }
        metadata {
          key
          value
        }
      }
      groups {
        name
        fields
        groupType
        metadata {
          key
          value
        }
      }
    }
  }
`;
