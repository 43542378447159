import React, { useEffect } from "react";
import { Form, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PreSchedule = ({ selectedDate, setDate, required }) => {
  useEffect(() => {}, []);

  const handleChange = (date) => setDate(date);

  return (
    <Form className="delivery-options-outer" style={{ marginBottom: 24 }}>
      <Segment>
        <Form.Field>
          <div className="ui header">
            {" "}
            Order scheduled date
            {required && <strong className="required-red"> *</strong>}
          </div>
          <label>If the order has already been scheduled, please enter the time here.</label>
        </Form.Field>
        <DatePicker selected={selectedDate} onChange={handleChange} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" />
      </Segment>
    </Form>
  );
};

PreSchedule.propTypes = {
  selectedDate: PropTypes.any,
  setDate: PropTypes.func,
  required: PropTypes.bool,
};

export default PreSchedule;
